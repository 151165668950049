<template>
  <div>
    <div class="mb-2 mt-3">
      <b-card no-body>
        <b-tabs pills card>
          <!--  **** Logs ****  -->
          <b-tab title="Logs" active>

          <b-button class="btn bg-transparent btn-outline-light" @click="refreshLogs">
          <b-icon icon="arrow-clockwise" variant="dark" scale="1" title="Refresh logs"></b-icon>
        </b-button>
                <b-table striped hover :items="logs"></b-table>
          </b-tab>

          <!--  **** Statistics  ****  -->
          <b-tab title="Statistics">
            <div class="all row mt-4">
              <div class="col-2"></div>
              <!--  **** Edge  ****  -->
              <div class="col-3">
                <b-table stacked :items="taskStatsArray">
                  <template #table-caption>Tasks</template></b-table
                >
              </div>
              <div class="col-2"></div>
              <!--  **** Separator  ****  -->

              <div class="col-3">
                <b-table stacked :items="fileStatsArray">
                  <template #table-caption>Files</template></b-table
                >
              </div>

              <div class="col-2"></div>
              <!--  **** Edge  ****  -->
            </div>
            
          </b-tab>
          <b-tab title="Edit">
            <b-card-text>
              <div class="all row mt-4">
                <div class="col-6">
                  <p class="subtitle">Source Name</p>
                  <b-form-input
                    v-model="planDetail.sourceName"
                    type="text"
                    :disabled="true"
                    class="shape col-9"
                  ></b-form-input>
                  <p class="subtitle mt-3">Source Email</p>
                  <b-form-input
                    v-model="planDetail.sourceEmail"
                    type="text"
                    :disabled="true"
                    class="shape col-9"
                  ></b-form-input>
                  <p class="subtitle mt-3">Source Parent</p>
                  <b-form-input
                    v-model="planDetail.sourceParent"
                    type="text"
                    :disabled="true"
                    class="shape col-9"
                  ></b-form-input>
                  <p v-if="targetEndpointType==0" class="subtitle mt-3" >
                    Migrate to Teams
                    <b-icon
                      icon="info-circle-fill"
                      scale="1"
                      variant="secondary"
                      title="Disable this toggle if you don't want to migrate to MS Teams"
                    ></b-icon>
                  </p>

                  <switchToggle v-if="targetEndpointType==0"
                    :switchState="planDetail.migrateToTeams"
                    @change-switch-state="changeState"
                  >
                  </switchToggle>
                </div>
                <div class="col-6">
                  <p class="subtitle">
                    {{generateTargetPlanName()}}
                    <b-icon
                      icon="info-circle-fill"
                      scale="1"
                      variant="secondary"
                      :title="generateTargetPlanNameInfoMessage()"
                    ></b-icon>
                  </p>
                  <b-form-input
                    v-model="planDetail.targetPlanName"
                    type="text"
                    :placeholder="generateTargetPlanName()"
                    class="shape col-9"
                  ></b-form-input>


                  <p class="subtitle mt-3">
                    {{generateTargetEmailNickName()}} 
                    <b-icon
                      icon="info-circle-fill"
                      scale="1"
                      variant="secondary"
                      :title="generateTargetEmailNickNameInfoMessage()"
                    ></b-icon>
                  </p>
                  <b-form-input
                    v-model="planDetail.targetEmail"
                    type="text"
                    :placeholder="generateTargetEmailNickName()"
                    class="shape col-9"
                  ></b-form-input>


                  <p class="subtitle mt-3">
                      {{generateTargetGroupName()}}
                    <b-icon
                      icon="info-circle-fill"
                      scale="1"
                      variant="secondary"
                      :title="generateTargetGroupNameInfoMessage()"
                    ></b-icon>
                  </p>
                  <b-form-input
                    v-model="planDetail.targetGroupName"
                    type="text"
                    :placeholder="generateTargetGroupName()"
                    class="shape col-9"
                  ></b-form-input>


                  <p v-if="targetEndpointType==0" class="subtitle mt-3">
                    Target Channel Name
                    <b-icon
                      icon="info-circle-fill"
                      scale="1"
                      variant="secondary"
                      title="Name of the Teams channel to migrate to. By default, the Plan will be created under the General channel"
                    ></b-icon>
                  </p>
                  <b-form-input v-if="targetEndpointType==0"
                    v-model="planDetail.targetChannel"
                    type="text"
                    placeholder="Example: General"
                    class="shape col-9"
                    :disabled="!planDetail.migrateToTeams"
                  ></b-form-input>
                </div>


                <div class="button mb-1">
                  <b-button 
                    variant="primary"
                    class="editbutton"
                    @click="updatePlan()"
                    >Update Details</b-button
                  >
                </div>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import switchToggle from "../inputs/switchToggle.vue";

export default {
  name: "plannerListDetails",
  components: {
    switchToggle,
  },
  data() {
    return {
      logs: [],
      stats: {},
      taskStatsArray: [],
      fileStatsArray: [],
      planDetail: {},
      planUpdate: {},
      detailOptions: "logs",
      project: null,
      targetEndpointType: null, 
      toggleState: true,
    };
  },
  props: {
    plannerId: Number,
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    api.validateToken().then(
      () => {},
      () => {
        this.$router.push({ path: "/login" });
      }
    );
    this.getPlannerLogs(this.project, this.plannerId);
    this.getPlannerStats(this.project, this.plannerId);
    this.getPlanOverview(this.project, this.plannerId);
    this.getTargetEndpointType(this.project);
  },
  methods: {
    refreshLogs(){
       this.getPlannerLogs(this.project, this.plannerId);
    },
    changeState() {
      this.planDetail.migrateToTeams = !this.planDetail.migrateToTeams;
    },
    updatePlan() {
      this.planUpdate = {
        targetGroupName: this.planDetail.targetGroupName,
        targetEmail: this.planDetail.targetEmail,
        targetPlanName: this.planDetail.targetPlanName,
        migrateToTeams: this.planDetail.migrateToTeams,
        targetChannelName: this.planDetail.targetChannel,
      };
      api.updatePlan(this.planUpdate, this.project, this.plannerId).then(() => {
        this.$bvModal.hide("logs");
      });
      this.$emit("update-plan-details", this.planDetail);
    },
    getPlannerLogs(projectId, plannerId) {
      api.getPlannerLogs(projectId, plannerId).then((response) => {
        this.logs = response.responseData;
        if (this.logs.length > 0) {
          this.logs.forEach((element) => {
            element.timestamp = element.timestamp
              .replace("T", " ")
              .split(".")[0];
          });
        } else {
          const date = new Date();
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const staticLog = {
            timestamp: year + "-" + month + "-" + day,
            message: "No logs to show.",
          };
          this.logs.push(staticLog);
        }
      });
    },
    getPlannerStats(projectId, plannerId) {
      api.getPlannerStats(projectId, plannerId).then((response) => {
        this.stats = response.responseData;
        this.taskStatsArray = [
          {
            Total: this.stats.totalTasks,
            Migrated: this.stats.migratedTasks,
            Error: this.stats.errorTasks,
            Ignored: this.stats.ignoredTasks,
          },
        ];
        this.fileStatsArray = [
          {
            Total: this.stats.totalFiles,
            Migrated: this.stats.migratedFiles,
            Error: this.stats.errorFiles,
            Ignored: this.stats.ignoredFiles,
          },
        ];
      });
    },
    getPlanOverview(projectId, plannerId) {
      api.getPlanOverview(projectId, plannerId).then((response) => {
        this.planDetail = response.responseData;
        this.toggleState = this.planDetail.migrateToTeams;
      });
    },
    getTargetEndpointType(projectId) {
      api.getTargetEndpointType(projectId).then((response) => {
        this.targetEndpointType = response.responseData;
      });
    },
    generateTargetPlanName() {
      switch(this.targetEndpointType){
        case 0: //Planner
        case 14: //Default
        default:
          return "Target Plan Name";
        case 1: //Trello
          return "Target Board Name"
        case 2: //Asana
          return "Target Project Name"
        case 13: //Monday
          return "Target Board Name"
      }
    },
    generateTargetPlanNameInfoMessage() {
      switch(this.targetEndpointType){
        case 0: //Planner
        case 14: //Default
        default:
          return "Name of the Plan in the target.";
        case 1: //Trello
          return "Name of your Trello destination board."
        case 2: //Asana
          return "Name of your Asana project in the target."
        case 13: //Monday
          return "Name of your Monday board in the target."
      }
    },
    generateTargetEmailNickName() {
      switch(this.targetEndpointType){
        case 0: //Planner
        case 14: //Default
        default:
          return "Target Email Nickname";
        case 1: //Trello
          return "Target Trello Nickname."
        case 2: //Asana
          return "Target Asana Workspace"
        case 13: //Monday
          return "Target Monday Workspace Name"
      }
    },
    generateTargetEmailNickNameInfoMessage() {
      switch(this.targetEndpointType){
        case 0: //Planner
          return "Nickname of the email of the M365 Group. If the Group's email is Marketing@company.com, the nickname is Marketing";
        case 1: //Trello
          return "This field is not required for Trello. You can leave it empty."
        case 2: //Asana
          return "Name of the your Asana Workspace. The Workspace MUST exist in the destination before running the migration."
        case 13: //Monday
          return "Name of your Monday.com Workspace. If the workspace does not exist, Altosio will create a new Workspace with this name."
        default:
        case 14: //Default
          return "Email nickname of your target environment.";
      }
    },
     generateTargetGroupName() {
      switch(this.targetEndpointType){
        case 0: //Planner
        case 14: //Default
        default:
          return "Target Group Name";
        case 1: //Trello
          return "Target Trello Organization."
        case 2: //Asana
          return "Target Asana Team Name"
        case 13: //Monday
          return "Target Folder Name"
      }
    },
    generateTargetGroupNameInfoMessage() {
      switch(this.targetEndpointType){
        case 0: //Planner
        case 14: //Default
        default:
          return "Display name of the Group in the target.";
        case 1: //Trello
          return "Name of your Trello Organization. The organization does not need to exist. It can be created by Altosio. If the organization exists in the target, Altosio will migrate the data into it."
        case 2: //Asana
          return "Name of your Asana Team. If the Team already exists, Altosio will migrate into it. Otherwise, it will create it."
        case 13: //Monday
          return "Name of your Monday.com Folder. If the folder already exists, Altosio will migrate into it. Otherwise, it will create it."
      }
    },
    showLogs() {
      this.detailOptions = "logs";
    },
    showStats() {
      this.detailOptions = "stats";
    },
    showEdit() {
      this.detailOptions = "edit";
    },
  },
};
</script>

<style scoped>
.all {
  margin-left: 60px;
}
.wrapper {
  height: 400px;
  overflow-y: scroll;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.botton {
  width: 150px;
  height: 35px;
  background: #23438e;
  border-radius: 5px;
  margin-left: 60px;
}
.tab {
  background: #23438e;
}
.editbutton {
  margin-top: 30px;
  margin-left: 200px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
</style>
