import axios from 'axios'
import store from '../store/index'
import router from '../router'

const API_ENDPOINT = '/api'
//const API_ENDPOINT = 'http://localhost:5000'


axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        router.push('/login');
    }
    return Promise.reject(error)
})

export default {
    register: async function (form) {
        const result = await axios.post(API_ENDPOINT + '/Register', form).then(
            (response) => { return response.data }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    login: async function (form) {
        const result = await axios.post(API_ENDPOINT + '/Login', form).then(
            (response) => { return response.data }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    saveSlackCode: async function (code) {
        const result = await axios.get(
            API_ENDPOINT + '/generateToken?code='+code,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getCustomer: async function () {
        const result = await axios.get(
            API_ENDPOINT + '/Customer',
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    validateToken: async function () {
        const result = await axios.get(
            API_ENDPOINT + '/validate',
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    addProject: async function (form) {
        const result = await axios.post(
            API_ENDPOINT + '/Project',
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getProjects: async function () {
        const result = await axios.get(
            API_ENDPOINT + '/Project',
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deleteProject: async function (projectId) {
        const result = await axios.delete(
            API_ENDPOINT + '/Project/' + projectId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    updateProject: async function (form, projectId) {
        const result = await axios.put(
            API_ENDPOINT + '/Project/' + projectId,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    getEndpoints: async function (id) {
        const result = await axios.get(
            API_ENDPOINT + '/Endpoint/' + id,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getTargetEndpointType: async function (projectId) {
        const result = await axios.get(
            API_ENDPOINT + '/Endpoint/TargetEndpoint/' + projectId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getEndpointLogs: async function (projectId, endpointId) {
        const result = await axios.get(
            API_ENDPOINT + '/Endpoint/Logs/' + projectId + '/' + endpointId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    addEndpoint: async function (form, id) {
        const result = await axios.post(
            API_ENDPOINT + '/Endpoint/' + id,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    updateEndpoint: async function (form, projectId, connectorId) {
        const result = await axios.put(
            API_ENDPOINT + '/Endpoint/' + projectId + '/' + connectorId,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getEndpoint: async function (projectId, connectorId) {
        const result = await axios.get(
            API_ENDPOINT + '/Endpoint/' + projectId + '/' + connectorId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getEndpointStatus: async function (id, type) {
        const result = await axios.get(
            API_ENDPOINT + '/Endpoint/States/' + id + '/' + type ,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deleteConnector: async function (projectId, connectorId) {
        const result = await axios.delete(
            API_ENDPOINT + '/Endpoint/' + projectId + '/' + connectorId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    testConnector: async function (projectId, connectorId) {
        const result = await axios.get(
            API_ENDPOINT + '/Endpoint/TestConnection/' + projectId + '/' + connectorId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    repullConnector: async function (projectId, connectorId) {
        const result = await axios.put(
            API_ENDPOINT + '/Endpoint/Repull/' + projectId + '/' + connectorId,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getPlannerOverview: async function (id) {
        const result = await axios.get(
            API_ENDPOINT + '/PlannerList/overview/' + id,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getPlannerBackupOverview: async function (id) {
        const result = await axios.get(
            API_ENDPOINT + '/PlannerBackup/overview/' + id,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getGroupOverview: async function (id) {
        const result = await axios.get(
            API_ENDPOINT + '/GroupList/overview/' + id,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getUserGroupOverview: async function (id) {
        const result = await axios.get(
            API_ENDPOINT + '/UserGroupList/overview/' + id,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deletePlanner: async function (projectId, plannerId) {
        const result = await axios.delete(
            API_ENDPOINT + '/PlannerList/' + projectId + '/' + plannerId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deletePlannerBackup: async function (projectId, plannerId) {
        const result = await axios.delete(
            API_ENDPOINT + '/PlannerBackup/' + projectId + '/' + plannerId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deleteGroup: async function (projectId, groupId) {
        const result = await axios.delete(
            API_ENDPOINT + '/GroupList/' + projectId + '/' + groupId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deleteUserGroup: async function (projectId, userId) {
        const result = await axios.delete(
            API_ENDPOINT + '/UserGroupList/' + projectId + '/' + userId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deletePlanners: async function (projectId, form) {
        const result = await axios.delete(
            API_ENDPOINT + '/PlannerList/delete/' + projectId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                },
                data:  {
                    PlannerIds: form
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deletePlannersBackups: async function (projectId, form) {
        const result = await axios.delete(
            API_ENDPOINT + '/PlannerBackup/delete/' + projectId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                },
                data:  {
                    PlannerIds: form
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deleteGroups: async function (projectId, form) {
        const result = await axios.delete(
            API_ENDPOINT + '/GroupList/delete/' + projectId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                },
                data:  {
                    GroupIds: form
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deleteUsersGroups: async function (projectId, form) {
        const result = await axios.delete(
            API_ENDPOINT + '/UserGroupList/delete/' + projectId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                },
                data:  {
                    UserIds: form
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    enableBackup: async function (projectId, plannerId, backupState) {
        const result = await axios.put(
            API_ENDPOINT + '/PlannerBackup/EnableBackup/' + projectId + '/' + plannerId+ '/'+ backupState,
            null,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    setBackupFrequency: async function (projectId, form) {
        const result = await axios.put(
            API_ENDPOINT + '/PlannerBackup/SetFrequency/' + projectId,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    assignTargetEndpoint: async function (id, data) {
        const result = await axios.put(
            API_ENDPOINT + '/PlannerList/AssignTarget/' + id,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    assignTargetEndpointForGroups: async function (id, data) {
        const result = await axios.put(
            API_ENDPOINT + '/GroupList/AssignTarget/' + id,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    assignTargetEndpointForUserGroups: async function (id, data) {
        const result = await axios.put(
            API_ENDPOINT + '/UserGroupList/AssignTarget/' + id,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    assignTargetEndpointForPlannerBackup: async function (id, data) {
        const result = await axios.put(
            API_ENDPOINT + '/PlannerBackup/AssignTarget/' + id,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getPlannerLogs: async function (projectId, plannerId) {
        const result = await axios.get(
            API_ENDPOINT + '/PlannerDetails/Logs/' + projectId + '/' + plannerId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getPlannerBackupLogs: async function (projectId, plannerId) {
        const result = await axios.get(
            API_ENDPOINT + '/PlannerBackupDetails/Logs/' + projectId + '/' + plannerId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getGroupLogs: async function (projectId, groupId) {
        const result = await axios.get(
            API_ENDPOINT + '/GroupDetails/Logs/' + projectId + '/' + groupId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getUserGroupLogs: async function (projectId, userId) {
        const result = await axios.get(
            API_ENDPOINT + '/UserGroupDetails/Logs/' + projectId + '/' + userId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getPlannerStats: async function (projectId, plannerId) {
        const result = await axios.get(
            API_ENDPOINT + '/PlannerStatistic/' + projectId + '/' + plannerId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getPlanOverview: async function (projectId, plannerId) {
        const result = await axios.get(
            API_ENDPOINT + '/PlannerList/' + projectId + '/' + plannerId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    getGroup: async function (projectId, groupId) {
        const result = await axios.get(
            API_ENDPOINT + '/GroupList/' + projectId + '/' + groupId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getUserGroup: async function (projectId, userId) {
        const result = await axios.get(
            API_ENDPOINT + '/UserGroupList/' + projectId + '/' + userId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getPlannerBackup: async function (projectId, PlannerBackupId) {
        const result = await axios.get(
            API_ENDPOINT + '/PlannerBackup/' + projectId + '/' + PlannerBackupId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    updatePlan: async function (form, projectId, planId) {
        const result = await axios.put(
            API_ENDPOINT + '/PlannerList/Update/' + projectId + '/' + planId,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    updatePlanBackup: async function (form, projectId, planId) {
        const result = await axios.put(
            API_ENDPOINT + '/PlannerBackup/Update/' + projectId + '/' + planId,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    updateGroup: async function (form, projectId, groupId) {
        const result = await axios.put(
            API_ENDPOINT + '/GroupList/Update/' + projectId + '/' + groupId,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    updateUserGroup: async function (form, projectId, userId) {
        const result = await axios.put(
            API_ENDPOINT + '/UserGroupList/Update/' + projectId + '/' + userId,
            "\"" +form+"\"" ,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    changePlanStatus: async function (projectId, plannerId, data) {
        const result = await axios.put(
            API_ENDPOINT + '/PlannerState/ChangeStatus/' + projectId + '/' + plannerId,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    changePlanBackupStatus: async function (projectId, plannerId, data) {
        const result = await axios.put(
            API_ENDPOINT + '/PlannerBackupState/ChangeStatus/' + projectId + '/' + plannerId,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    changeGroupStatus: async function (projectId, groupId, data) {
        const result = await axios.put(
            API_ENDPOINT + '/GroupState/ChangeStatus/' + projectId + '/' + groupId,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    changeUserGroupStatus: async function (projectId, userId, data) {
        const result = await axios.put(
            API_ENDPOINT + '/UserGroupList/ChangeStatus/' + projectId + '/' + userId,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    getUserMappings: async function (id) {
        const result = await axios.get(
            API_ENDPOINT + '/UserMapping/' + id,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    anyEmptyMappings: async function (id) {
        const result = await axios.get(
            API_ENDPOINT + '/UserMapping/AnyEmptyMappings/' + id,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    getUserMapping: async function (projectId, mappingId) {
        const result = await axios.get(
            API_ENDPOINT + '/UserMapping/mapping/' + projectId + '/' + mappingId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    addUserMapping: async function (form, id) {
        const result = await axios.post(
            API_ENDPOINT + '/UserMapping/mapping/' + id,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    updateUserMapping: async function (form, projectId, userId) {
        const result = await axios.put(
            API_ENDPOINT + '/UserMapping/mapping/' + projectId + '/' + userId,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    deleteUserMapping: async function (projectId, userId) {
        const result = await axios.delete(
            API_ENDPOINT + '/UserMapping/mapping/' + projectId + '/' + userId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },

    loadAccount: async function () {
        const result = await axios.get(
            API_ENDPOINT + '/Customer',
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    updateProfile: async function (form) {
        const result = await axios.put(
            API_ENDPOINT + '/customer/UpdateProfile',
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    updatePassword: async function (form) {
        const result = await axios.put(
            API_ENDPOINT + '/customer/UpdatePassword',
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    resetPassword: async function (form) {
        const result = await axios.put(
            API_ENDPOINT + '/ResetPassword',
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    resetPasswordLink: async function (form) {
        const result = await axios.post(
            API_ENDPOINT + '/ResetPasswordLink',
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    getLicenseSummary: async function () {
        const result = await axios.get(
            API_ENDPOINT + '/License/Summary',
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    addLicenses: async function (form, customerId) {
        const result = await axios.post(
            API_ENDPOINT + '/License/' + customerId,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    confirmAccount: async function (id) {
        const result = await axios.get(
            API_ENDPOINT + '/confirm/' + id,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    getLicenseItems: async function () {
        const result = await axios.get(
            API_ENDPOINT + '/License/Types',
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    addOrder: async function (form) {
        return await axios.post(
            API_ENDPOINT + '/Order/',
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
    },
    updateOrder: async function (form, orderId) {
        const result = await axios.put(
            API_ENDPOINT + '/Order/' + orderId,
            form,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    getOrderById: async function (orderId) {
        const result = await axios.get(
            API_ENDPOINT + '/Order/' + orderId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
    getOrderByPayPalId: async function (orderId) {
        const result = await axios.get(
            API_ENDPOINT + '/Order/ByPaypalId' + orderId,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + store.getters.getToken,
                    accept: "*/*",
                }
            }
        ).then(
            (response) => {
                return response.data
            }
        ).catch(
            error => { return Promise.reject(error.response.data); }
        )
        return result;
    },
}
